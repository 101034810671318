@import '../../../../assets/styles/variables';

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 230px;

  &:not(:last-child) {
    margin-bottom: 60px;
  }

  .icon {
    width: 147px;
    height: 118px;
    stroke: @primary-color;
    fill: @primary-color;
  }

  .title {
    text-align: center;
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .description {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    white-space: pre-line;
  }
}

@media screen and (min-width: @screen-md-min) {
  .steps {
    display: flex;
    justify-content: space-between;
    max-width: 1070px;
    width: 100%;
  }

  .step {
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

@primary-color: #1967DD;