.form-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  background-color: #fff;
  padding-top: 14px;
}
.ant-form-large .non-input-formitem .ant-form-item-control-input {
  min-height: 10px;
}
p,
a,
span,
h1,
div {
  font-family: 'Inter', -apple-system, Tahoma;
}
.btn.primary {
  background-color: #1967DD;
  color: #fff;
  border-radius: 20px;
  border: 1px solid transparent;
}
.btn.primary:hover {
  background-color: #fff;
  border: 1px solid #1967DD;
  color: #1967DD;
}
.btn.default {
  border-radius: 20px;
}
.btn.default:hover {
  border: 1px solid #1967DD;
  color: #1967DD;
}
.ant-input:focus,
.ant-input:visited,
.ant-input:hover,
.ant-input:active,
.ant-picker:hover,
.ant-input:focus,
.ant-input:active,
.ant-select:hover,
.ant-input-password:hover,
.ant-input-password:active,
.ant-input-password:focus,
.ant-select-selector:hover {
  border-color: #d7d7d7 !important;
}
.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}
.ant-space-item {
  width: 100%;
}
.offers-page {
  min-height: calc(100vh - 80px);
}
.offers-page__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
}
.offers-page__header-info {
  width: 100%;
}
.offers-page__header-info p:first-child {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
}
.offers-page__header-info p:last-child {
  font-size: 16px;
}
.offers-page__header-info span {
  display: block;
  font-size: 14px;
  margin-bottom: 20px;
}
.offers-page__header-btn {
  border: 1px solid #d7d7d7;
  border-radius: 20px;
  padding: 8px 30px;
  color: #000;
  font-size: 15px;
}
.offers-page__header-btn:hover {
  color: #000;
}
.offers-page__header-btn span:first-child {
  margin-right: 10px;
}
.offers-page__header-btn span:last-child {
  margin-left: 10px;
}
.offers-page__icons {
  display: flex;
  margin-top: 10px;
}
.offers-page__icons-item {
  display: flex;
  align-items: center;
  padding-right: 18px;
}
.offers-page__icons-item img {
  margin-right: 8px;
}
.offers-page__icons .boat-page__icons-item img {
  margin-right: 10px;
}
.offers-page__location {
  margin: 16px 0;
  color: #9c9696;
}
.offers-page__text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.offers-page__text img {
  height: 28px;
}
.offers-page__text p {
  margin: 30px 10px;
  font-size: 16px;
  font-weight: 500;
}
.offers-page__menu {
  display: flex;
}
.offers-page__menu-sort {
  margin-right: 20px;
  width: 170px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}
.offers-page__menu-sort .ant-select-selector {
  border-radius: 20px !important;
  font-size: 16px;
  height: 35px !important;
}
.offers-page__menu-sort-icon {
  font-size: 14px;
  color: #000000;
}
.offers-page__menu-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.offers-page__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.offers-page-sort {
  display: flex;
  align-items: center;
}
.offers-page-sort p {
  margin-bottom: 0;
  margin-right: 4px;
  font-size: 16px;
}
.offers-page__popover {
  padding: 10px 10px 0 10px;
  width: auto;
  min-width: 320px;
}
.offers-page__popover-row {
  display: flex;
}
.offers-page__popover-row > div {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 50%;
}
.offers-page__popover-row > span {
  color: #a5a3a3;
  width: 50%;
}
.offers-page__popover p {
  font-weight: 500;
  font-size: 14px;
}
.offers-page__popover p.price {
  margin-right: 4px;
}
.offers-page__popover span.additional {
  display: block;
  color: #a5a3a3;
  white-space: nowrap;
}
.offers-page__popover p.green {
  color: #1967DD;
}
.offers-page__popover p.old-price {
  color: red;
  text-decoration: line-through;
}
.offers-page-item {
  display: flex;
  align-items: flex-start;
  border-radius: 20px;
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;
}
.offers-page-item__top {
  display: flex;
  border-bottom: 1px solid #d7d7d7;
}
.offers-page-item__img {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 320px;
}
.offers-page-item__img .offers-img {
  width: 100%;
  min-height: 215px;
}
.offers-page-item__img .offers-img img {
  width: 100%;
  height: 100%;
}
.offers-page-item__title {
  width: 100%;
  max-width: 850px;
}
.offers-page-item__title h3 {
  font-weight: 500;
  font-size: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
.offers-page-item__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px;
  width: 100%;
}
.offers-page-item__rating {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  right: 10px;
  padding: 5px 8px;
}
.offers-page-item__rating:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: #000;
  padding: 5px;
  opacity: 0.4;
  filter: alpha(opacity=70);
  /* opicity in IE */
  color: #fff;
  border-radius: 8px;
}
.offers-page-item__rating span {
  display: block;
  color: #fff;
  margin-right: 5px;
  z-index: 1;
}
.offers-page-item__rating img {
  z-index: 1;
}
.offers-page-item__info {
  flex-grow: 1;
}
.offers-page-item__info > span {
  color: #a5a3a3;
}
.offers-page-item__slider {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.offers-page-item__additional {
  display: flex;
}
.offers-page-item__additional > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 35px;
}
.offers-page-item__additional img {
  height: 32px;
  width: 32px;
  margin: 7px auto 10px auto;
}
.offers-page-item__additional span {
  color: #a5a3a3;
  text-align: center;
  font-size: 12px;
}
.offers-page-item__price {
  width: 30%;
  text-align: center;
}
.offers-page-item__price span {
  display: block;
  font-weight: 500;
}
.offers-page-item__price-mobile {
  display: none;
}
.offers-page-item__price-mobile .offers-page__popover {
  padding: 0;
}
.offers-page-item__price-mobile .offers-page__popover-row {
  margin-bottom: 10px;
  width: 100%;
  padding-right: 4px;
  align-items: center;
}
.offers-page-item__price-mobile .offers-page__popover-row span {
  font-size: 13px;
  text-align: left;
  width: 55%;
}
.offers-page-item__price-mobile .offers-page__popover-row > div {
  padding-left: 4px;
  align-items: center;
}
.offers-page-item__price-mobile .offers-page__popover .price {
  font-size: 16px;
  margin-right: 2px;
}
.offers-page-item__price-mobile .offers-page__popover .price.additional {
  font-size: 12px;
}
.offers-page-item__price-mobile .ant-collapse-content-box {
  padding: 0;
}
.offers-page-item__price-desktop {
  display: block;
}
.offers-page-item__description {
  display: flex;
}
.offers-page-item__description > div {
  flex-direction: column;
  margin-right: 30px;
}
.offers-page-item__description > div span {
  display: block;
  margin-top: 10px;
  color: #a5a3a3;
}
.offers-page-item .old-price {
  color: red;
  text-decoration: line-through;
}
.offers-page-item .price {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
}
.offers-page-item .price span {
  display: block;
  font-size: 16px;
  margin-left: 5px;
  cursor: pointer;
}
.offers-page-item p {
  margin: 0;
}
.offers-page-item__actions {
  flex-grow: 1;
  /*a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 15px;
        color: #fff;
        border-radius: 20px;
        &:first-child {
          background-color: #4285F4;
          margin-bottom: 10px;
          transition: 0.2s;
          border: 1px solid transparent;
          &:hover {
            border: 1px solid #4285F4;
            color: #4285F4;
            background-color: #fff;
          }
        }
        &:last-child {
          background-color: @primary-color;
          border: 1px solid transparent;
          transition: 0.2s;
          &:hover {
            background-color: #fff;
            border: 1px solid @primary-color;
            color: @primary-color;
          }
        }
      }*/
}
.offers-page-item--disabled {
  color: #9c9696 !important;
  background-color: #e9e9e9;
}
.offers-page-item--disabled .offers-page-item__content h3 {
  color: #9c9696 !important;
}
.offers-page-item--disabled .offers-img {
  filter: grayscale(100%);
}
.offers-page__no-offers {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}
.offers-page .container {
  display: flex;
  flex-direction: column;
  max-width: 1300px;
}
.offers-page h2 {
  font-size: 40px;
  font-weight: 500;
}
@media screen and (max-width: 1250px) {
  .offers-page__items {
    width: 100%;
    display: grid;
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(310px, 360px));
    justify-content: center;
    margin: 0 auto;
    padding: 0;
  }
  .offers-page__popover-row span {
    width: 50% !important;
  }
  .offers-page-item {
    flex-direction: column;
  }
  .offers-page-item__info {
    width: 100%;
  }
  .offers-page-item__content {
    flex-direction: column;
  }
  .offers-page-item__content h3 {
    font-size: 18px;
  }
  .offers-page-item__img {
    width: 100%;
    max-width: 100%;
  }
  .offers-page-item__additional {
    flex-wrap: wrap;
  }
  .offers-page-item__additional > div {
    text-align: center;
    margin: 0;
    width: 33%;
  }
  .offers-page-item__additional .circle {
    width: 22px;
    height: 22px;
  }
  .offers-page-item__actions {
    flex-grow: 1;
    width: 100%;
  }
  .offers-page-item__price-mobile {
    display: block;
    width: 100%;
    border-top: 1px solid #d7d7d7;
    margin-top: 10px;
  }
  .offers-page-item__price-mobile .anticon {
    right: 16px;
    left: auto !important;
    margin-bottom: 10px;
  }
  .offers-page-item__price-mobile .ant-collapse-header {
    padding: 20px 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .offers-page-item__price-desktop {
    display: none;
  }
  .offers-page__icons {
    justify-content: space-between;
  }
  .offers-page__icons-item p {
    font-size: 12px;
  }
  .offers-page__icons-item img {
    height: 50%;
    width: 50%;
    margin-right: 5px;
  }
}
