@import './../variables';

@border-color: #d7d7d7;

.offers-page {
  min-height: calc(100vh - 80px);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 10px;
    &-info {
      width: 100%;
      p {
        &:first-child {
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 0;
        }
        &:last-child {
          font-size: 16px;
        }
      }
      span {
        display: block;
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
    &-btn {
      border: 1px solid @border-color;
      border-radius: 20px;
      padding: 8px 30px;
      color: #000;
      font-size: 15px;
      &:hover {
        color: #000;
      }
      span:first-child {
        margin-right: 10px;
      }
      span:last-child {
        margin-left: 10px;
      }
    }
  }
  &__icons {
    display: flex;
    margin-top: 10px;
    &-item {
      display: flex;
      align-items: center;
      padding-right: 18px;
      img {
        margin-right: 8px;
      }
    }
    .boat-page__icons-item {
      img {
        margin-right: 10px;
      }
    }
  }
  &__location {
    margin: 16px 0;
    color: #9c9696;
  }
  &__text {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 28px;
    }
    p {
      margin: 30px 10px;
      font-size: 16px;
      font-weight: 500;
    }
  }
  &__menu {
    display: flex;
    &-sort {
      margin-right: 20px;
      width: 170px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      .ant-select-selector {
        border-radius: 20px !important;
        font-size: 16px;
        height: 35px !important;
      }
      &-icon {
        font-size: 14px;
        color: #000000;
      }
    }
    &-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      margin-right: 10px;
      box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  &-sort {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0;
      margin-right: 4px;
      font-size: 16px;
    }
  }
  &__popover {
    padding: 10px 10px 0 10px;
    width: auto;
    min-width: 320px;
    &-row {
      display: flex;
      & > div {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 50%;
      }
      & > span {
        color: #a5a3a3;
        width: 50%;
      }
    }
    p {
      font-weight: 500;
      font-size: 14px;
    }
    p.price {
      margin-right: 4px;
    }
    span.additional {
      display: block;
      color: #a5a3a3;
      white-space: nowrap;
    }
    p.green {
      color: @primary-color;
    }
    p.old-price {
      color: red;
      text-decoration: line-through;
    }
  }
  &-item {
    display: flex;
    align-items: flex-start;
    border-radius: 20px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
    &__top {
      display: flex;
      border-bottom: 1px solid @border-color;
    }
    &__img {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 320px;
      .offers-img {
        width: 100%;
        min-height: 215px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &__title {
      width: 100%;
      max-width: 850px;
      h3 {
        font-weight: 500;
        font-size: 22px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
      }
    }
    &__content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 15px;
      width: 100%;
    }
    &__rating {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 10px;
      right: 10px;
      padding: 5px 8px;
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: #000;
        padding: 5px;
        opacity: 0.4;
        filter: alpha(Opacity=70); /* opicity in IE */
        color: #fff;
        border-radius: 8px;
      }
      span {
        display: block;
        color: #fff;
        margin-right: 5px;
        z-index: 1;
      }
      img {
        z-index: 1;
      }
    }
    &__info {
      flex-grow: 1;
      & > span {
        color: #a5a3a3;
      }
    }
    &__slider {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    &__additional {
      display: flex;
      & > div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-right: 35px;
      }
      img {
        height: 32px;
        width: 32px;
        margin: 7px auto 10px auto;
      }
      span {
        color: #a5a3a3;
        text-align: center;
        font-size: 12px;
      }
    }
    &__price {
      width: 30%;
      text-align: center;
      span {
        display: block;
        font-weight: 500;
      }
      &-mobile {
        display: none;
        .offers-page__popover {
          padding: 0;
          &-row {
            margin-bottom: 10px;
            width: 100%;
            padding-right: 4px;
            align-items: center;
            span {
              font-size: 13px;
              text-align: left;
              width: 55%;
            }
            & > div {
              padding-left: 4px;
              align-items: center;
            }
          }
          .price {
            font-size: 16px;
            margin-right: 2px;
          }
          .price.additional {
            font-size: 12px;
          }
        }
        .ant-collapse-content-box {
          padding: 0;
        }
      }
      &-desktop {
        display: block;
      }
    }
    &__description {
      display: flex;
      > div {
        flex-direction: column;
        margin-right: 30px;
        span {
          display: block;
          margin-top: 10px;
          color: #a5a3a3;
        }
      }
    }
    .old-price {
      color: red;
      text-decoration: line-through;
    }
    .price {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 500;
      span {
        display: block;
        font-size: 16px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
    p {
      margin: 0;
    }
    &__actions {
      flex-grow: 1;
      /*a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 15px;
        color: #fff;
        border-radius: 20px;
        &:first-child {
          background-color: #4285F4;
          margin-bottom: 10px;
          transition: 0.2s;
          border: 1px solid transparent;
          &:hover {
            border: 1px solid #4285F4;
            color: #4285F4;
            background-color: #fff;
          }
        }
        &:last-child {
          background-color: @primary-color;
          border: 1px solid transparent;
          transition: 0.2s;
          &:hover {
            background-color: #fff;
            border: 1px solid @primary-color;
            color: @primary-color;
          }
        }
      }*/
    }
  }
  &-item--disabled {
    .offers-page-item__content h3 {
      color: #9c9696 !important;
    }

    color: #9c9696 !important;
    background-color: #e9e9e9;
    .offers-img {
      filter: grayscale(100%);
    }
  }
  &__no-offers {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
  }
  .container {
    display: flex;
    flex-direction: column;
    max-width: 1300px;
  }
  h2 {
    font-size: 40px;
    font-weight: 500;
  }
}

@media screen and (max-width: 1250px) {
  .offers-page {
    &__items {
      width: 100%;
      display: grid;
      grid-gap: 2em;
      grid-template-columns: repeat(auto-fit, minmax(310px, 360px));
      justify-content: center;
      margin: 0 auto;
      padding: 0;
    }
    &__popover {
      &-row {
        span {
          width: 50% !important;
        }
      }
    }
    &-item {
      flex-direction: column;
      &__info {
        width: 100%;
      }
      &__content {
        flex-direction: column;
        h3 {
          font-size: 18px;
        }
      }
      &__img {
        width: 100%;
        max-width: 100%;
      }
      &__additional {
        flex-wrap: wrap;
        & > div {
          text-align: center;
          margin: 0;
          width: 33%;
        }
        .circle {
          width: 22px;
          height: 22px;
        }
      }
      &__actions {
        flex-grow: 1;
        width: 100%;
      }
      &__price {
        &-mobile {
          display: block;
          width: 100%;
          border-top: 1px solid @border-color;
          margin-top: 10px;
          .anticon {
            right: 16px;
            left: auto !important;
            margin-bottom: 10px;
          }
          .ant-collapse-header {
            padding: 20px 0 !important;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        &-desktop {
          display: none;
        }
      }
    }
    &__icons {
      justify-content: space-between;
      &-item {
        p {
          font-size: 12px;
        }
        img {
          height: 50%;
          width: 50%;
          margin-right: 5px;
        }
      }
    }
  }
}
