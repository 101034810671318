.label {
  position: relative;

  :global {
    .ant-input,
    .ant-input-number-input,
    .ant-input-affix-wrapper > input.ant-input {
      padding: 37px 12px 14px 30px;
      height: 70px;
    }

    .ant-input-password {
      padding: 0 16px 0 0;
    }

    /*.ant-select-auto-complete {
      padding: 37px 10px 14px 30px;
    }*/

    .ant-select .ant-select-selector {
      padding: 37px 10px 14px 30px;
      height: 70px;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 37px 10px 14px 30px;
      height: 70px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-search {
      top: 16px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-search {
      top: 30px;
      left: 30px;
    }
  }
}

.text {
  position: absolute;
  top: 25px;
  left: 30px;
  pointer-events: none;
  transition: all 0.2s ease;

  color: #828282;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  z-index: 1; // because input[type=password]
}

.textFloat {
  z-index: 3; // because react-phone-input-2
  top: 14px;
  font-size: 12px;
}

.labelFloat {
}

.label.small {
  :global {
    .ant-input,
    .ant-input-number-input,
    .ant-input-affix-wrapper > input.ant-input {
      padding: 29px 12px 12px 30px;
      height: 56px;
    }
  }

  .text {
    top: 20px;
  }

  .textFloat {
    top: 8px;
    font-size: 12px;
  }
}

@primary-color: #1967DD;