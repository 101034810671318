@import './../../../../assets/styles/variables';

@photoWeight: 160px;

.main {
  padding-bottom: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.photo {
  margin: 16px 0;

  img {
    border-radius: 12px;
    width: 100%;
  }
}

.info {
  .title {
    margin: 8px 0 0 0;
    font-size: 24px;
    font-weight: 600;

    a {
      color: #000000;
    }
  }

  .rating {
    display: flex;
    align-content: center;
    margin: 2px 0 12px;
    line-height: 22px;

    .icon {
      height: 22px;
      margin-left: 4px;
    }
  }

  .price {
    display: flex;
    align-items: center;
    margin: 8px 0 8px 0;

    .all {
      padding-right: 16px;
      font-size: 18px;
      font-weight: 600;
    }

    .pricePerDay {
      color: #a5a3a3;
    }
  }

  .wrapperIcons {
    width: 100%;
    max-width: 280px;
  }
}

@media screen and (min-width: @screen-lg-min) {
  .main {
    display: flex;
    align-content: flex-start;
    width: 100%;
  }

  .info {
    width: calc(100% - @photoWeight);

    .price {
      margin: 28px 0 8px 0;
    }
  }

  .photo {
    width: @photoWeight;
    height: 140px;
    margin-right: 32px;

    img {
      width: @photoWeight;
      max-height: 140px;
      border-radius: 12px;
    }
  }
}

@primary-color: #1967DD;