// Spacings helper аналогичный https://getbootstrap.com/docs/4.0/utilities/spacing/
@spacings: {
  0: 0;
  1: 0.25rem;
  2: 0.5rem;
  3: 1rem;
  4: 1.5rem;
  5: 3rem;
};

// Margins
each(@spacings, {
  .mt-@{key} {
    margin-top: @value;
  }

  .mb-@{key} {
    margin-bottom: @value !important;
  }

  .ml-@{key} {
    margin-left: @value !important;
  }

  .my-@{key} {
    margin: @value 0 !important;
  }
});

// Paddings
each(@spacings, {
  .pa-@{key} {
    padding: @value !important;
  }
});
