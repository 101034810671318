.container {
  margin-top: 40px;
}

.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 25px;
}

.item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  .label {
    max-width: 480px;
    color: #828282;
  }

  .value {
    white-space: nowrap;
    text-align: right;
  }
}

@primary-color: #1967DD;