@import '../../assets/styles/variables';

@heightText: 145px;
@heightGradient: 20px;

.content {
  height: @heightText;
  margin-bottom: 8px;
  margin-top: -(@heightGradient);
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  line-height: 21px;

  &:before {
    content: '';
    display: block;
    position: relative;
    height: @heightGradient;
    top: calc(@heightText - @heightGradient);
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 100%);
  }
}

.show {
  display: block;
  position: relative;
  padding-top: 12px;
  bottom: 10px;
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
  cursor: pointer;
  color: @primary-color;
}

input.shadyCheckbox {
  display: none;
}

input.shadyCheckbox[type='checkbox']:checked ~ .content {
  height: unset;
  margin-top: 0;
}

input.shadyCheckbox[type='checkbox']:checked ~ .content:before {
  display: none;
}

input.shadyCheckbox[type='checkbox']:checked ~ .show {
  display: none;
}

@primary-color: #1967DD;