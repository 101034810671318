@import '../../../assets/styles/variables';

.OldCategoryCards {
  :global {
    .ant-card-actions {
      border: none;
    }
  }
}

.categories,
.categoriesSpinner {
  display: flex;
  position: relative;
  left: -20px;
  width: calc(100% + 20px);
  justify-content: space-between;
  min-height: 181px;
  padding: 15px 20px !important;
}

.categoriesSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.category {
  display: flex;
  width: 176px !important;
  height: 145px !important;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 15px 0;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fafafa;
  cursor: pointer;
  box-shadow: 0 3px 24px 0 rgba(201, 200, 200, 0.5);
  border-radius: 4px;

  .icon {
    display: block;
    width: 154px;
    height: 69px;
  }

  .text {
    text-align: center;

    .name {
      font-size: 12px;
      line-height: 15px;
      padding-bottom: 4px;
    }

    .price {
      font-size: 12px;
      line-height: 15px;

      .from,
      .price {
        color: #9b9b9b;
      }

      .value {
        padding: 0 4px;
        font-weight: 600;
      }
    }
  }
}

.selectedCategory {
  border: 1px solid @primary-color;
}

@media screen and (max-width: @screen-sm-max) {
  .categories,
  .categoriesSpinner {
    position: relative;
    left: -(@container-padding);
    width: calc(100% + 36px) !important;
  }
}

@primary-color: #1967DD;