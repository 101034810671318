.container {
  padding: 0 18px;
}

.title {
  margin: 1em 0 0.5em;
  width: 100%;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}

.subTitle {
  margin-top: 32px;
}

@primary-color: #1967DD;