@import '../../../../assets/styles/variables';

@padding: 16px;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #f9f9f9;
}

.main {
  width: 100%;
}

.title {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  padding: 60px 20px 16px 20px;
  margin: 0;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 0 20px 40px 20px;
}

.link {
  display: block;
  width: 100%;
  padding: 0 20px 42px 20px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  width: 100%;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.image {
  position: relative;
  width: 100%;

  picture,
  img {
    display: block;
    width: 100%;
    aspect-ratio: 335 / 333;
  }
}

.shadow {
  position: absolute;
  background: linear-gradient(180deg, #f9f9f9 3%, rgba(249, 249, 249, 0) 18.2%);
  width: 100%;
  height: 100%;
  top: -3px;
  left: 0;
}

// Cheat for PageSpeed
@media screen and (min-width: 360px) and (max-width: 360px) {
  .image {
    width: 320px;
    height: 320px;

    picture,
    img {
      width: 320px;
      height: 320px;
    }
  }
}

// tab
@media screen and (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
  .shadow {
    background: linear-gradient(180deg, #f9f9f9 3%, rgba(249, 249, 249, 0) 18.2%);
    top: -3px;
    left: 0;
  }

  .image {
    picture,
    img {
      aspect-ratio: 728 / 335;
    }
  }
}

// desc
@media screen and (min-width: @screen-lg-min) {
  .shadow {
    background: linear-gradient(90deg, #f9f9f9 3%, rgba(249, 249, 249, 0) 18.2%);
    top: 0;
    left: -3px;
  }

  .image {
    position: relative;
    width: 530px;

    picture,
    img {
      display: block;
      width: 530px;
      aspect-ratio: 530 / 482;
    }
  }
}

@media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .title {
    padding: 60px 20px 15px 40px;
  }

  .description {
    padding: 0 20px 40px 40px;
    max-width: 100%;
  }

  .link {
    padding: 0 20px 60px 40px;
    max-width: 266px;
  }
}

@media screen and (min-width: @screen-lg-min) {
  .container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    height: 482px;
    padding: 0 0 0 100px;
  }

  .title {
    padding: 100px 0 20px 0;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
  }

  .description {
    padding: 0 0 60px 0;
    font-size: 16px;
  }

  .link {
    padding: 0;
    max-width: 226px;
  }
}

@primary-color: #1967DD;