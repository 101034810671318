.form {
  padding: 24px;
  border-radius: 15px;
  box-shadow: 0 3px 24px 0 rgba(219, 219, 219, 0.5);
}

.categories {
  width: 100%;

  .category {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 4px;
    cursor: pointer;

    .label {
      margin-left: 16px;

      .icon {
        display: block;
        width: 85px;
        height: 38px;
      }
    }

    .icon {
      width: 85px;
      min-height: 38px;
    }
  }
}

@primary-color: #1967DD;