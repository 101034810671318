@import '../../../../assets/styles/variables';

.categories {
  width: 100%;
  padding: 0 @container-padding !important;

  :global {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}

.slide {
  width: 200px !important;
  height: 270px !important;
}

.category,
.name {
  width: 200px;
  height: 270px;
}

.category {
  display: block;
  position: relative;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #ffffff;
  border-radius: 15px;

  picture,
  img,
  .name {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  .name {
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 62.33%, rgba(0, 0, 0, 0.7) 100%);
    padding: 30px 30px 27px 30px;
    color: #ffffff;
  }
}

@media screen and (min-width: @screen-md-min) {
  .slide {
    width: 280px !important;
    height: 380px !important;
  }

  .category,
  .name {
    width: 280px;
    height: 380px;
    font-size: 22px;
    line-height: 26px;
  }
}

@primary-color: #1967DD;