@import './../../../../assets/styles/variables';

.spaceComponent {
  min-width: 75px;
  margin-left: 0.5rem;

  .icon {
    margin-left: 0.5rem;
  }
}

.selectorLink {
  color: #000000;
  transition: all 0.4s;
  cursor: pointer;

  &:hover {
    color: @primary-color;
  }
}

.userProfileMenu {
  .cardUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    border-bottom: 1px solid #f0f0f0;

    .avatar {
      display: block;
      margin: auto;
    }

    .name {
      margin: 0.5rem 0;
      color: rgba(0, 0, 0, 0.45);
    }

    .linkAdmin {
      display: flex;
      justify-content: center;
    }
  }

  .item {
    display: block;
    padding: 9px 15px;

    .icon {
      margin-right: 8px;
    }
  }
}

@primary-color: #1967DD;