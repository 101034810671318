.main {
}

.min {
  font-size: 16px;
  color: #828282;
  line-height: 20px;
}

.big {
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  margin-top: 7px;
}

.skeletonTiny {
  height: 20px;
}

.skeletonBig {
  margin-top: 7px;
  margin-bottom: 30px;
  height: 31px;
}

@primary-color: #1967DD;