@import '../../../../assets/styles/variables';

@weightCol: 200px;
@weightColDesktop: 260px;

.specifications,
.equipment {
  margin-bottom: 40px;

  .title {
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 25px;
  }

  .parameter {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}

.specifications {
  .parameter {
    display: flex;
    flex-wrap: wrap;

    .label {
      min-width: @weightCol;
      color: #828282;
    }
  }
}

.equipment {
  .list {
    padding-left: 26px;
  }
}

@media screen and (min-width: @screen-sm-min) {
  .specifications {
    .parameter {
      .label {
        min-width: @weightColDesktop;
        color: #828282;
      }
    }
  }

  .equipment {
    .list {
      column-count: 2;
      max-width: calc(@weightColDesktop * 2);
      padding-left: 26px;

      .parameter {
        max-width: @weightCol;
        margin-bottom: 14px;
      }
    }
  }
}

@primary-color: #1967DD;