@import './../../assets/styles/variables';

@border-color: #e5e5e5;
@border-radius: 12px;

.container {
  padding: 0 18px;
}

.back {
  font-weight: 700;
  font-size: 22px;
  margin: 8px 0;

  svg {
    margin-right: 16px;
  }

  a,
  a:hover {
    color: #000000;
    text-decoration: none;
  }
}

.info {
  margin: 8px 0;
  border: 1px solid @border-color;
  border-radius: @border-radius;

  .options,
  .price {
    padding: 18px;
  }

  .options,
  .price {
    border-top: 1px solid @border-color;
  }
}

.alert {
  // border-left: 3px solid #C43A39;
  // padding: 0 0 0 28px;
  margin: 32px 0 0 0;

  .title {
    font-weight: bold;
  }

  .text {
    display: flex;
    align-items: center;
    color: #c43a39;

    svg {
      fill: #c43a39;
      margin-right: 4px;
    }
  }
}

.contactForm {
  margin: 32px 0;

  .title {
    font-size: 18px;
    font-weight: 700;
  }

  .wrapperInput {
    margin: 12px 0;
  }

  .input {
    padding-left: 16px;
    border-radius: 4px;
    border: 1px solid #e1e1e1;
    height: 56px;
  }
}

.payment {
  margin: 24px 0;
}

.billingInfo {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  font-size: 18px;
  font-weight: 700;
}

.billingForm {
  .form {
    margin: 16px 0;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding: 16px;
  }

  .freezeInfo {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .wrapperButton {
    display: flex;
    justify-content: center;
  }

  .buttonSubmit {
    width: 100%;
    max-width: 180px;
    margin: 8px 0;
    background-color: @primary-color;
    height: 44px;
    border-radius: 22px;
    font-size: 16px;
    color: #ffffff;
  }
}

.secureInfo {
  margin: 8px 0;
  display: flex;
  justify-content: center;
  line-height: 16px;
  width: 100%;
  color: #8e8e8e;
  font-size: 12px;

  svg {
    margin-right: 8px;
  }
}

.platronInfo {
  margin: 8px 0;
  text-align: center;
  width: 100%;
  color: #8e8e8e;
  font-size: 12px;

  a {
    color: #8e8e8e;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.selectorCurrency {
  display: flex;
  justify-content: right;
  align-items: center;

  .label {
    margin-right: 12px;
  }

  .input {
    width: 100px;
  }
}

@media screen and (min-width: @screen-md-min) {
  .wrapperInfoForm {
    display: flex;
    justify-content: space-between;

    .info {
      min-width: 455px;
      margin-right: 60px;
      flex-grow: 0;
    }

    .form {
      flex-grow: 1;
      //width: 100%;
    }

    .alert {
      margin-top: 8px;
    }
  }
}

@media screen and (min-width: @screen-md-min) AND (max-width: @screen-md-max) {
  .wrapperInfoForm {
    .info {
      min-width: 355px;
      margin-right: 42px;
    }
  }
}

@primary-color: #1967DD;