.block {
  .icon {
    color: #1a212c !important;
    font-size: 14px !important;
  }

  :global {
    .ant-collapse-item {
      padding-bottom: 30px;
    }

    .ant-collapse-header {
      font-size: 16px;
      font-weight: 600;
      user-select: none;
      color: #000000 !important;
    }

    .ant-collapse-content {
      color: #000000;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }

    .ant-collapse-header,
    .ant-collapse-content-box {
      padding-left: 0 !important;
    }

    .ant-collapse-content-box {
      padding-bottom: 0 !important;
      padding-right: 56px !important;
    }
  }
}

@primary-color: #1967DD;