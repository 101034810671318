@import '../../assets/styles/variables';

.parameters {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.parameter {
  display: flex;
  align-items: center;

  &.low {
    font-size: 12px;
  }
}

.icon {
  max-height: 22px;
  margin-right: (@main-gap / 4);

  &.low {
    margin-right: 12px;
  }
}

.skeleton {
  max-width: 260px;
  height: 22px;
  margin-bottom: 15px;
}

@primary-color: #1967DD;