.main {
  display: flex;
  align-content: center;

  .icon {
    margin: 2px 8px 0 1px;
  }
}

.skeleton {
  height: 22px;
  margin-bottom: 6px;
  width: 46px;
}

@primary-color: #1967DD;