.promoCode {
  font-size: 14px;

  .description {
    font-size: 16px;
  }

  .description,
  .code,
  .discount {
    margin-bottom: 4px;
  }
}

@primary-color: #1967DD;