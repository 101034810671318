@import './../../assets/styles/variables';

@photoWeight: 160px;

.back {
  font-weight: 700;
  font-size: 22px;
  margin: 8px 0;

  .icon {
    margin-right: 12px;
  }

  .link,
  .link:hover {
    color: #000000;
    text-decoration: none;
  }
}

.parameterList {
  margin: 34px 0 16px 0;
}

.agent {
  margin: 16px 0;
}

/*
.calendars {
  color: #8e8e8e;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .google,
  .apple {
    display: flex;
    align-items: center;

    svg {
      margin-right: 6px;
      width: 18px;
      height: 18px;
    }
  }
}
*/

@media screen and (min-width: @screen-lg-min) {
  .layout {
    display: flex;
    justify-content: space-between;

    .layoutLeft {
      width: 100%;
      max-width: 600px;
      margin-right: 24px;
    }

    .layoutRight {
      width: 100%;
      max-width: 455px;
    }
  }
}

@primary-color: #1967DD;