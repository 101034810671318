@import './../../assets/styles/variables';

.layout {
  .filter,
  .result {
    margin-top: 12px;
  }
}

.title {
  font-size: 22px;
}

.subTitle {
  margin-bottom: 22px;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  color: #000000;
}

.description {
  color: #6b6b6b;
}

.result {
  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    margin-bottom: 8px;
  }

  .skeletonTitle {
    height: 39px;
    max-width: 360px;
  }

  .breadcrumbs {
    margin-bottom: 32px;
    color: #6b6b6b;
  }

  .skeletonBreadcrumbs {
    height: 22px;
    max-width: 240px;
  }
}

.blockDescription {
  margin-bottom: 68px;
}

.boats {
  margin-bottom: 68px;
  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(282px, 1fr));
    grid-gap: 25px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 28px;

  :global {
    .ant-pagination {
      font-size: 16px;
    }

    .ant-pagination-item-link,
    .ant-pagination-item {
      border-radius: 16px;
    }

    .ant-pagination-item {
      border: 1px solid #000000;
      color: #000000;
    }

    .ant-pagination-item-active,
    .ant-pagination-item:hover {
      border: 1px solid @primary-color;
      color: @primary-color;
    }

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      border: 1px solid #d0d0d0;
      color: #d0d0d0;
    }
  }
}

@media screen and (min-width: @screen-lg-min) {
  .layout {
    display: flex;
    max-width: 1600px;
    flex-flow: row;
  }

  .filter {
    min-width: 300px;
    flex: 0 0 auto;
    margin-right: 16px;
  }

  .result {
    flex: 1 1 auto;
    margin-left: 16px;
  }

  .subTitle {
    font-size: 40px;
    line-height: 44px;
  }
}

@primary-color: #1967DD;