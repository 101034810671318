.form {
  height: 80px;
  border-radius: 40px;
  box-shadow: 0 3px 24px rgba(219, 219, 219, 0.5);
  margin-bottom: 44px;

  .locations {
    width: 100%;
    height: 80px;
    border-radius: 50%;

    :global {
      .ant-select-selector {
        padding: 24px 0 20px 26px;
        height: 80px;
        border: none;
        border-radius: 40px;

        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }

      .ant-select-selection-search {
        position: absolute;
        top: 24px;
        right: 6px;
        bottom: 30px;
        left: 26px;
      }
    }
  }
}

@primary-color: #1967DD;