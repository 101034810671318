@borderRadius: 8px;

.review {
  margin-bottom: 20px;
}

.card {
  max-width: 282px;
  box-shadow: 0 3px 24px rgba(219, 219, 219, 0.5);
  border-radius: @borderRadius;

  .boatPhoto {
    img {
      width: 100%;
      height: 180px;
      object-fit: cover;
      border-top-right-radius: @borderRadius;
      border-top-left-radius: @borderRadius;
    }
  }

  .body {
    padding: 12px 12px 0 12px;

    .boatName {
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
    }

    .rating {
      margin: 6px 0 6px 2px;
    }

    .divider {
      margin: 12px 0;
      border-top: 1px solid #f4f4f4;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }

  .arrow {
    position: relative;
    width: 12px;
    height: 12px;
    right: -21px;
    transform: translateY(50%) rotate(135deg);
    background-color: #ffffff;
    //box-shadow: 0 3px 24px rgba(219, 219, 219, 0.5);
  }
}

.author {
  display: flex;
  padding: 25px 8px 8px 8px;

  .photo {
    height: 42px;
    width: 42px;
    img {
      width: 100%;

      border-radius: 50%;
    }
  }

  .text {
    padding-left: 6px;
    .name {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      margin-bottom: 4px;
    }

    .date {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #828282;
    }
  }
}

@primary-color: #1967DD;