@import './../../../assets/styles/variables';

.arrowLeft {
  width: 10px;
  height: 16px;
  fill: none;
}

.avatar {
  width: 68px;
  height: 68px;
}

.clock {
  width: 16px;
  height: 16px;
}

.lock {
  width: 12px;
  height: 16px;
  fill: #c8c8c8;
}

.payment {
  width: 20px;
  height: 21px;
  fill: @primary-color;
}

@primary-color: #1967DD;