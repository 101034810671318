@import '../../assets/styles/variables';

.title {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  margin-bottom: 30px;
}

.form {
  margin: 0 auto;
  max-width: 514px;
}

.wrap {
  padding: 8px 8px 8px 16px;
  border-radius: 10px;
  background-color: #fff;
}

.btn {
  margin: 0 auto;
  min-width: 100%;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 31px !important;
}

.link {
  border-bottom: 1px solid @primary-color;
  color: @primary-color;
  margin-left: 5px;
  transition: 0.2s;

  &:hover {
    border-color: transparent;
    color: @primary-color;
  }
}

.additional {
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;

  a {
    border-bottom: 1px solid @primary-color;
    color: @primary-color;
    transition: 0.2s;

    &:hover {
      border-color: transparent;
    }
  }
}

@media screen and (max-width: 500px) {
  .wrap {
    padding: 25px;
  }
}

@primary-color: #1967DD;