@import './../../assets/styles/variables';

@borderRadius: 15px;

.card {
  width: 280px;
  height: 385px;
  box-shadow: 0 3px 16px rgba(219, 219, 219, 0.3);
  border-radius: 15px;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;

  .photo {
    width: 100%;
    height: 180px;

    :global {
      .ant-image {
        width: 100%;
      }
    }

    img,
    .skeletonPhoto {
      width: 100%;
      height: 180px;
      object-fit: cover;
      border-top-left-radius: 15px !important;
      border-top-right-radius: 15px !important;
    }
  }

  .info {
    padding: 20px;

    .specifications {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 6px;
    }

    .name {
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      padding-bottom: 7px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .rating {
      display: flex;
      align-content: center;
      padding-bottom: 6px;

      .icon {
        margin: 2px 8px 0 1px;
      }
    }

    .location {
      display: flex;
      align-content: center;
      color: #828282;
      padding-bottom: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .icon {
        margin: 2px 8px 0 2px;
      }
    }

    .price {
      font-size: 16px;
      line-height: 20px;

      .value {
        font-weight: 700;
      }
    }
  }
}

.skeletonSpecifications {
  height: 18px;
  margin: 2px 0 0 0;
  width: 100%;
}

.skeletonName {
  height: 16px;
  margin: 3px 0 4px 0;
  width: 40%;
}

.skeletonRating {
  height: 14px;
  margin: 2px 0 4px 0;
  width: 30%;
}

.skeletonLocation {
  height: 14px;
  margin: 2px 0 4px 0;
  width: 80%;
}

.skeletonPrice {
  height: 14px;
  margin: 5px 0 8px 0;
  width: 60%;
}

.skeletonPerWeek {
  height: 14px;
  margin: 2px 0 4px 0;
  width: 30%;
}

@primary-color: #1967DD;