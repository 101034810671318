@import '../../../../assets/styles/variables';

.categories {
  width: 100%;
}

.main {
  margin: 20px 0;

  .panelLeft {
  }

  .panelRight {
  }
}

.panelSubmit {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  width: 100%;

  .wrapperSubmit {
    max-width: 520px;
    width: 100%;

    .linkUserAgreement {
      margin: 20px auto;
      max-width: 400px;
    }
  }
}

.flex {
  .subFlex {
    margin-bottom: 0;
  }
}

.tabs {
  margin-bottom: 30px;
}

///////////////////////

.map {
  margin-bottom: 25px;
}

.extraOptions {
  margin-top: 25px;
  padding: 30px;
  border: 1px solid #cccccc;
  border-radius: 4px;

  .title {
    font-size: 14px;
    color: #828282;
    margin-bottom: 12px;
  }

  :global {
    .ant-checkbox-wrapper {
      font-size: 16px;
      //line-height: 21px;
    }
  }

  .field {
    margin-bottom: 12px;
  }
}

.match {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: @primary-color;
  fill: @primary-color;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;

  &:hover {
    color: darken(@primary-color, 10%);

    .icon {
      fill: darken(@primary-color, 10%);
    }
  }

  .icon {
    margin-right: 12px;
  }

  span {
    display: flex;
    align-items: center;
  }
}

////////////////

.wrapperPhone {
  :global {
    @flagButtonWidth: 92px;
    .react-tel-input {
      font-family: @font-family;
      height: 70px;

      .flag-dropdown {
        background: unset;
        border: 1px solid #d0d0d0;

        .selected-flag {
          width: @flagButtonWidth;
          padding-left: 30px;
        }
      }

      .form-control {
        min-height: 70px;
        padding: 37px 12px 14px @flagButtonWidth + 30px;
        border: 1px solid #d0d0d0;
        border-radius: 2px;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}

.label {
  line-height: 2.5em;
}

.wrapperCategory {
  width: 100%;
}

.promoCodeOpener {
  margin-bottom: 20px;
}

.promoCode {
  .result {
    margin-bottom: 24px;
    font-size: 16px;
  }
}

// Button Offer your price
.priceMax {
  :global {
    .ant-checkbox-wrapper {
      height: 35px;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 500;

      .ant-checkbox {
        display: none;
      }

      span {
        display: flex;
        align-items: center;
        height: 35px;
        padding: 0 20px;
        width: 180px;
        color: @primary-color;
        background-color: lighten(@primary-color, 45%);
        border-radius: 35px;
      }
    }
  }

  .wrapperInput {
    margin: 0;

    .fieldPriceMax {
      width: 100%;
    }
  }
}

.priceMaxOpen {
  :global {
    .ant-checkbox-wrapper {
      span {
        color: #000000;
        background-color: #f4f4f4;
      }
    }
  }
}

.bannerMobile {
  display: block;
  margin-bottom: 22px;

  img {
    display: block;
    width: 100%;
  }
}

.times {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;

  .time {
    width: 100%;
  }
}

.hours {
  margin-bottom: 23px;

  .label {
    padding-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    color: #828282;
    text-align: left;
    user-select: none;
  }
}

@media screen and (min-width: @screen-md-min) {
  .flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    .subFlex {
      width: 48%;
    }
  }

  .guests {
    order: 1;
  }

  .hours {
    width: 100%;
    order: 2;
  }

  .times {
    display: flex;
    justify-content: space-between;
    width: 48%;
  }
}

@media screen and (min-width: @screen-lg-min) {
  .bannerMobile {
    display: none !important;
  }
}

@media screen and (min-width: @screen-lg-min) {
  .main {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .panelLeft {
      width: 58%;
      margin-bottom: 20px;
    }

    .panelRight {
      width: 38%;
      height: 240px;
      margin-bottom: 20px;
    }
  }
}

@primary-color: #1967DD;