.main {
  display: flex;
  flex-flow: row wrap;
  row-gap: 16px;
  max-width: 420px;
  justify-content: center;

  .title {
    padding: 48px 0 24px 0;
    font-weight: bold;
    text-align: center;
  }
}

@primary-color: #1967DD;