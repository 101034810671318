.link {
  color: #000000;

  &:hover {
    color: #000000;
  }
}

.pricePerDay {
  margin-top: 16px;
}

@primary-color: #1967DD;