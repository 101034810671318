@import '../../../../assets/styles/variables';

.form {
  :global {
    .ant-checkbox-wrapper {
      font-size: 16px;
    }
  }
}

.captain {
  margin-bottom: 20px;
}

.linkUserAgreement {
  margin-top: 24px;
}

@primary-color: #1967DD;