.withoutDiscount,
.total,
.firstPayment,
.secondPayment {
  display: flex;
  justify-content: space-between;
}

.promoCode {
  font-weight: 500;
  margin-bottom: 8px;

  .description {
    margin-bottom: 4px;
  }

  .code,
  .discount {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    .value {
      font-weight: bold;
    }
  }
}

.total {
  font-size: 16px;
  font-weight: bold;
}

.price {
  color: #8a8a8a;
  font-size: 12px;
}

.firstPayment {
  font-weight: 500;
  padding: 8px 0;
}

.secondPayment {
  padding: 8px 0 0 0;
}

@primary-color: #1967DD;