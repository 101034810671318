@import '../../../../assets/styles/variables';

.countries {
  width: 100%;
  padding: 0 @container-padding !important;
  height: 360px;

  :global {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}

.slide {
  width: 280px !important;
  height: 360px !important;
}

.country {
  width: 280px;
  height: 360px;
}

.country {
  display: block;
  position: relative;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: #ffffff;
  border-radius: 15px;

  picture,
  img,
  .text {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
  }

  .text {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding: 30px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 62.33%, rgba(0, 0, 0, 0.7) 100%);
    color: #ffffff;

    .numberYachts {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      padding-bottom: 4px;
    }

    .name {
      font-size: 22px;
      font-weight: 700;
      line-height: 27px;
    }
  }

  .textIsMobile {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.7) 100%);
  }
}

@media screen and (min-width: @screen-xl-min) {
  .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
  }

  .countries {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 1200px;
    height: 660px;
    padding: 0 0 0 20px !important;
  }

  .slide,
  .country {
    &:nth-child(1) {
      width: 580px;
      height: 240px;
    }

    &:nth-child(2) {
      width: 580px;
      height: 400px;
    }

    &:nth-child(3) {
      width: 280px;
      height: 360px;
    }

    &:nth-child(4) {
      width: 280px;
      height: 280px;
    }

    &:nth-child(5) {
      width: 280px;
      height: 280px;
    }

    &:nth-child(6) {
      width: 280px;
      height: 360px;
    }
  }
}

@primary-color: #1967DD;