@import '../../assets/styles/variables';

.modal {
  .main {
    text-align: center;
  }
}

.changeTab {
  color: @primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

@primary-color: #1967DD;