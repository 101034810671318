.layoutHeader {
  position: sticky;
  top: 0;
  z-index: 99;
  padding: 0;
  height: auto;  
}

.layoutContent {
  flex: 1 0 auto;
}

.layoutFooter {
  flex: 0 0 auto;
  padding: 24px 0;
}
@primary-color: #1967DD;