.main {
  display: flex;
  justify-content: center;
  margin: 10px 0;

  .buttonSubmit {
    margin: 8px 0;
  }
}

@primary-color: #1967DD;