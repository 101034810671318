@import './../../../../assets/styles/variables';

.selectorLink {
  color: #000000;
  transition: all 0.4s;
  cursor: pointer;

  &:hover {
    color: @primary-color;
  }
}

@primary-color: #1967DD;