@import '../../../../assets/styles/variables';

.swiper {
  padding: 0 20px !important;

  .slide {
    width: 280px;
  }
}

.list {
  .item {
    border: 1px solid #e5e5e5;
    border-radius: 15px;
    padding: 24px;

    .head {
      display: flex;
      flex-flow: row;
      align-items: center;
      margin-bottom: 16px;

      .avatar {
        margin-right: 16px;
      }
    }

    .name {
      margin-bottom: 8px;
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }
  }
}

@media screen and (min-width: @screen-xl-min) {
  .list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
    width: 1180px;
    height: 510px;
    padding: 0 0 0 20px !important;

    .item {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        max-width: 342px;
      }

      &:nth-child(5),
      &:nth-child(6) {
        max-width: 456px;
      }

      &:nth-child(1) {
        min-height: 300px;
      }

      &:nth-child(2) {
        min-height: 163px;
      }

      &:nth-child(3) {
        min-height: 180px;
      }

      &:nth-child(4) {
        min-height: 282px;
      }

      &:nth-child(5) {
        min-height: 248px;
      }

      &:nth-child(6) {
        min-height: 197px;
      }
    }
  }
}

@primary-color: #1967DD;