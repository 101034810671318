@import './../../assets/styles/variables';

.block {
  margin-bottom: 80px;
}

.blockWithShadow {
  margin-bottom: calc(80px - 16px);
}

.block,
.blockWithShadow {
  padding: 0;
}

.form {
  margin-bottom: 60px;
}

.exploreBlock {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.subTitleBlock {
  .linkMore {
    line-height: 36px;
    margin-bottom: 0 !important;
  }
}

.collapse {
  width: 100%;
  padding: 0 20px;
}

@media screen and (min-width: @screen-lg-min) {
  .block {
    margin-bottom: 100px;
  }

  .blockWithShadow {
    margin-bottom: calc(100px - 16px);
  }

  .form {
    margin-bottom: 100px;
    max-width: 962px;
    padding: 0;
  }
}

.subTitleBlock {
  .subTitle,
  .linkMore {
    line-height: 36px;
    margin-bottom: 24px;
  }

  .linkMore {
    padding-left: 20px;
  }
}

.subTitle {
  font-size: 30px;
  padding-left: @container-padding;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 24px;
}

@media screen and (min-width: @screen-lg-min) {
  .subTitle {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 24px;
  }
}

@media screen and (min-width: @screen-md-min) {
  .subTitleBlock {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .subTitle {
      order: -2;
    }

    .linkMore {
      order: -1;
    }
  }
}

@primary-color: #1967DD;