@import '../../../assets/styles/variables';

@marginItem: 6px;

.list {
  display: flex;
  margin: 0 -@marginItem;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px + @marginItem * 2;
  height: 43px + @marginItem * 2;
  border-radius: @marginItem * 2;
  border: @marginItem solid #ffffff;
  font-size: 16px;
  color: #000000;
  background-color: #f4f4f4;
  cursor: pointer;
  user-select: none;
}

.select,
.select:hover {
  color: @primary-color;
  background-color: #dcecfb;
}

@media screen and (min-width: @screen-lg-min) {
  .item:hover,
  .hover {
    color: #ffffff;
    background-color: lighten(@primary-color, 20%);
  }
}

@primary-color: #1967DD;