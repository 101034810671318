@import './../../../assets/styles/variables';

@paddingMobile: 0 11px 0 16px !important;
@paddingDesktop: 0 11px !important;

.spin {
  font-size: 24px;
  color: @primary-color !important;
}

.inputPreview {
  display: block !important;
  padding: @paddingMobile;
  min-height: 30px !important;
  height: 30px !important;
}

.inputLoading {
  padding: @paddingMobile;
  min-height: 30px !important;
  height: 30px !important;
  line-height: 25.144px;

  :global {
    .ant-input {
      margin-bottom: 0 !important;
      font-feature-settings: normal;
      font-variant-numeric: normal;
      line-height: 25.144px;
    }
  }
}

.datePicker {
  padding: @paddingMobile;

  :global {
    .ant-picker {
      padding: @paddingMobile;
    }

    input {
      min-height: 30px !important;
      height: 30px !important;
    }
  }
}

@media screen and (min-width: @screen-md-min) {
  .inputPreview,
  .inputLoading {
    padding: @paddingDesktop;
  }
  .datePicker {
    padding: @paddingDesktop;

    :global {
      .ant-picker {
        padding: @paddingDesktop;
      }
    }
  }
}

@primary-color: #1967DD;