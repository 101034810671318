.main {
  display: flex;
  row-gap: 16px;

  .title {
    margin: 1em 0 0.5em;
    width: 100%;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 38px;
    line-height: 1.23;
  }

  .navigation {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .partnership {
      margin-right: 24px;
    }
  }
}

@primary-color: #1967DD;