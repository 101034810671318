@import './../../assets/styles/variables.less';

// Desktop first
.swiperComponent {
  width: 100%;
  height: 100%;

  :global {
    .swiper-slide {
      display: flex;
      justify-content: center;
    }

    .swiper-button-next,
    .swiper-button-prev {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      padding: 0 17px;
      background: #ffffff;
      border-radius: 40px;
      border: 1px solid #d0d0d0;
      font-size: 10px !important;
      font-weight: bold;
      color: #000000;
    }

    .swiper-button-next::after,
    .swiper-button-prev:after {
      font-size: 10px;
    }

    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
      opacity: 0;
    }

    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: 3px;
    }

    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: 3px;
    }
  }
}

// Mobile second
@media screen and (max-width: @screen-md-max) {
  .swiperComponent {
    :global {
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
      }
    }
  }
}

@primary-color: #1967DD;