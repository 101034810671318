@import './../variables.less';

@ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);

.form-wrap {
  border: 1px solid hsv(0, 0, 85%);
  border-radius: 10px;
  background-color: #fff;
  padding-top: 14px;
}

.ant-form-large .non-input-formitem .ant-form-item-control-input {
  min-height: 10px;
}

p,
a,
span,
h1,
div {
  font-family: @font-family;
}
.page-shadow() {
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
}

.page-pro() {
  border-radius: 6px;
  border: 1px solid #e9e9e9;
  transform: translateY(0);
  transition: transform 0.3s @ease-out, box-shadow 0.3s @ease-out;
  &:hover {
    .page-shadow();
    transform: translateY(-5px);
  }
}

.btn {
  &.primary {
    background-color: @primary-color;
    color: #fff;
    border-radius: 20px;
    border: 1px solid transparent;
    &:hover {
      background-color: #fff;
      border: 1px solid @primary-color;
      color: @primary-color;
    }
  }
  &.default {
    border-radius: 20px;
    &:hover {
      border: 1px solid @primary-color;
      color: @primary-color;
    }
  }
}
.ant-input:focus,
.ant-input:visited,
.ant-input:hover,
.ant-input:active,
.ant-picker:hover,
.ant-input:focus,
.ant-input:active,
.ant-select:hover,
.ant-input-password:hover,
.ant-input-password:active,
.ant-input-password:focus,
.ant-select-selector:hover {
  border-color: #d7d7d7 !important;
}
.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}
.ant-space-item {
  width: 100%;
}
