@import '../../../../assets/styles/variables';

.swiper {
  .slide {
    width: 250px;
    justify-content: flex-start !important;
  }
}

.reviews {
  .title {
    font-size: 28px;
    font-weight: 700;
  }
}

@media screen and (min-width: @screen-lg-min) {
  .reviews {
    .list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      grid-gap: 31px;
    }
  }
}

@primary-color: #1967DD;