@import './../../../../assets/styles/variables';

.title {
  width: 100%;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

.content {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

@media screen and (min-width: @screen-lg-min) {
  .title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

@primary-color: #1967DD;