@import './variables.less';

.container {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-self: center;
  margin: auto;
  padding: 0 @container-padding;
}

.containerHigh {
  min-height: calc(100vh - 80px);
}

.make-container(@minWidth, @breakpoint) {
  @media (min-width: @minWidth) {
    .container {
      max-width: @breakpoint;
    }
  }
}

.make-container(@screen-xs-min, @screen-xs);
.make-container(@screen-sm-min, @screen-sm);
.make-container(@screen-md-min, @screen-md);
.make-container(@screen-lg-min, @screen-lg);
.make-container(@screen-xl-min, @screen-xl);
//.make-container(@screen-xxl-min, @screen-xxl);

@primary-color: #1967DD;