@import './../../../../assets/styles/variables';

.title {
  padding: 0 30px;
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  margin: 40px auto 16px auto;
}

.subTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  margin-bottom: 60px;
}

@media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .title {
    margin: 60px auto 10px auto;
  }
}

@media screen and (min-width: @screen-lg-min) {
  .title {
    font-size: 52px;
    line-height: 63px;
    margin: 90px auto 10px auto;
    padding: 0;
  }

  .subTitle {
    margin-bottom: 70px;
  }
}

@primary-color: #1967DD;