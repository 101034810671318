@import '../../../../assets/styles/variables';

.title {
  font-size: 18px;
  font-weight: 700;
}

.wrapperInput {
  margin: 24px 0;

  :global {
    .ant-input::placeholder {
      color: #5b5b5b;
    }
  }
}

.wrapperSubmit {
  margin-top: 24px;
}

.iconCheck {
  font-size: 16px;
  line-height: 16px;
  fill: @primary-color;
}

@primary-color: #1967DD;