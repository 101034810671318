.map {
  min-height: 300px;
}

.staticMap {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

@primary-color: #1967DD;