@import './variables';
@import '~antd/es/style/themes/default.less';
@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry

@layout-body-background: #fff;
@layout-header-background: #fff;

@collapse-header-padding: 0;
@collapse-header-bg: unset;
@collapse-content-padding: 0;
@collapse-content-bg: unset;
@collapse-panel-border-radius: 0;

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
}

select,
textarea,
input:not([type='checkbox']):not([type='radio']):not([type='button']):not([type='range']):not([readonly]),
.ant-picker-input > input,
.ant-select-selection-search-input,
.ant-input-number-input,
textarea.ant-input,
.ant-input:placeholder-shown {
  font-size: 16px;
}

.form-wrap {
  border: 1px solid hsv(0, 0, 85%);
  border-radius: 10px;
  background-color: #fff;
  padding-top: 14px;
}

.ant-form-large .non-input-formitem .ant-form-item-control-input {
  min-height: 10px;
}

.ant-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ant-col:first-child {
  padding-left: 0 !important;
}

.ant-col:last-child {
  padding-right: 0 !important;
}

// Temporarily. Need to do <DatePicker panelRender=
.ant-picker-header-super-next-btn,
.ant-picker-header-super-prev-btn {
  display: none;
}
